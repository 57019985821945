import { IAppDataContent } from "@/interfaces/appdata";
import { ICategoryWithProducts } from "@/interfaces/products";
import React from "react";
import { DefaultLayout } from "../layouts/DefaultLayout"

const MyAccount = ({ pageContext }: MyAccountProps) => {
    
    
    const {
        templateData,
    } = pageContext.appData
    const defaultLayoutData = {  pageContext, templateData, title: '' }

    return (
        <DefaultLayout { ...defaultLayoutData}>
            <div style={{ padding: '40px' }}>
                <div className="planne-widget planne-widget-account"></div>
            </div>
        </DefaultLayout>
    )
}

interface MyAccountProps{
    pageContext: {
        appData: IAppDataContent,
        lang: string,
        categoriesWithProducts: ICategoryWithProducts[],
        currentPath: string;
        pathLang: string;
        widgetTarget: string;
    }
}

export default MyAccount;
